import { push } from "connected-react-router";
import { Action, Middleware, Reducer } from "redux";
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { ApplicationState } from ".";
import { Message, UserDetail, Credential, OptionItem } from "../models";
import { viewSettingOption } from "../layouts/DashboardNavbar";
import { store } from "..";

interface FetchState {
  requested: boolean;
  loading: boolean;
  success?: boolean;
  data?: any;
  error?: any;
}

interface FormState<T = any> {
  initialValue: T;
  value: T;
  error: boolean;
  pristine: boolean;
  errors: {
    [fieldName in keyof T]?: string;
  };
  touched: {
    [fieldName in keyof T]?: boolean;
  };
  submitting: boolean;
  validationNeeded: boolean;
}

export interface AppState {
  loading: boolean;
  messages: Message[];
  user: UserDetail | null;
  fetch: {
    [url: string]: FetchState;
  };
  forms: {
    [url: string]: FormState;
  };
  container: {
    producerCode: number;
    date: string;
  };
  searchParams: string;
  pageSize: number;
  backData:any|null;
  isRefresh:boolean;
  settingView:any;
  settingBase:any;
}

const generateRandomUUID = () => {
  // UUIDs have 16 byte values
  const bytes = new Uint8Array(16);

  // Seed bytes with cryptographically random values
  crypto.getRandomValues(bytes);
  // Set required fields for an RFC 4122 random UUID
  bytes[6] = (bytes[6] & 0x0f) | 0x40;
  bytes[8] = (bytes[8] & 0x3f) | 0x80;
  // Convert bytes to hex and format appropriately
  const uuid = Array.prototype.map
    .call(bytes, (b, i) => {
      // Left-pad single-character values with 0,
      // Convert to hexadecimal,
      // Add dashes
      return (
        (b < 16 ? "0" : "") +
        b.toString(16) +
        (i % 2 && i < 10 && i > 2 ? "-" : "")
      );
    })
    .join("");
  // Return the string
  return uuid;
};

//---------------------------
// ログイン
//---------------------------
// コマンド
export interface ChangePassAction {
  type: "CHANGE_PASS";
  payload: {
    currentPassword: string;
    newPassword: string;
  };
}
export interface LoginAction {
  type: "LOGIN";
  payload: {
    credential: Credential;
  };
}
export interface InitAppAction {
  type: "INIT_APP";
}
export interface ClearSessionAction {
  type: "CLEAR_SESSION";
}
// イベント
export interface AppLoadedAction {
  type: "APP_LOADED";
}
export interface SessionFetchedAction {
  type: "SESSION_FETCHED";
  payload: { cognitoUser: CognitoUser };
}
export interface SessionExpiredAction {
  type: "SESSION_EXPIRED";
}
export interface AuthorizeAction {
  type: "AUTHORIZE";
  payload: { userProfile: UserDetail };
}

//---------------------------
// メッセージ表示
//---------------------------
// コマンド
export interface ShowMessageAction {
  type: "SHOW_MESSAGE";
  payload: {
    message: Message;
    dissmissAfterMs: number;
  };
}
export interface DismissMessageAction {
  type: "DISMISS_MESSAGE";
  payload: { messageId: string };
}
// イベント
export interface MessageAddedAction {
  type: "MESSAGE_ADDED";
  payload: Message;
}
export interface MessageRemovedAction {
  type: "MESSAGE_REMOVED";
  payload: Message;
}

//---------------------------
// データ取得
//---------------------------
// コマンド
type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";
export interface FetchAction {
  type: "FETCH";
  payload: {
    fetchId: string;
    url: string;
    method: HttpMethod;
    params?: { [key: string]: any };
    multipart: boolean;
    json: boolean;
  };
}
export interface InitFetchAction {
  type: "INIT_FETCH";
  payload: { fetchId: string };
}
export interface DiscardFetchAction {
  type: "DISCARD_FETCH";
  payload: { fetchId: string };
}
export interface DownloadFileAction {
  type: "DOWNLOAD_FILE";
  payload: {
    fetchId: string;
    title: string;
    expectedFilename: string;
    expectedMimeType: string;
    url: string;
    params?: { [key: string]: any };
    method?: string;
    searchParams?: boolean;
  };
}
// イベント
export interface FetchCompletedAction {
  type: "FETCH_COMPLETED";
  payload: { fetchId: string; success: boolean; data?: any; error?: any };
}

export interface SetSearchParamsAction {
  type: "SET_SEARCH_PARAMS";
  payload: { params: string };
}

export interface SetPageSizeAction {
  type: "SET_PAGE_SIZE";
  payload: { pageSize: number };
}

//---------------------------
// データ編集
//---------------------------
// コマンド
export interface InitFormAction {
  type: "INIT_FORM";
  payload: { formId: string; initialValue: { [key: string]: any } };
}
export interface TouchFormAction {
  type: "TOUCH_FORM";
  payload: { formId: string; key: string };
}
export interface ChangeFormAction {
  type: "CHANGE_FORM";
  payload: { formId: string; name: string; newValue: any };
}
export interface ValidateFormAction {
  type: "VALIDATE_FORM";
  payload: { formId: string; errors: FormState["errors"] };
}
export interface SubmitFormAction {
  type: "SUBMIT_FORM";
  payload: { formId: string };
}
export interface ResetFormAction {
  type: "RESET_FORM";
  payload: { formId: string };
}
export interface DiscardFormAction {
  type: "DISCARD_FORM";
  payload: { formId: string };
}
// イベント
export interface FormSubmittedAction {
  type: "FORM_SUBMITTED";
  payload: { formId: string };
}
export interface FormSubmissionCompletedAction {
  type: "FORM_SUBMISSION_COMPLETED";
  payload: { formId: string; success: boolean; returnData?: any; error?: any };
}

export interface SetBackDataAction{
  type:"SET_BACK_DATA",
  payload:{ data:any; }
}

export interface setRefreshDashboard{
  type:"SET_IS_REFRESH_DASHBOARD",
  payload:{ isRefresh:boolean; }
}

export interface setViewSettingDashboard{
  type:"SET_VIEW_SETTING_DASHBOARD",
  payload:{data:any}
}

export interface setBaseSettingDashboard{
  type:"SET_BASE_SETTING_DASHBOARD",
  payload:{data:any},
}

type KnownAction =
  | LoginAction
  | ChangePassAction
  | InitAppAction
  | AppLoadedAction
  | ClearSessionAction
  | SessionFetchedAction
  | SessionExpiredAction
  | AuthorizeAction
  | ShowMessageAction
  | DismissMessageAction
  | MessageAddedAction
  | MessageRemovedAction
  | FetchAction
  | InitFetchAction
  | DiscardFetchAction
  | DownloadFileAction
  | FetchCompletedAction
  | InitFormAction
  | TouchFormAction
  | ChangeFormAction
  | ValidateFormAction
  | SubmitFormAction
  | ResetFormAction
  | DiscardFormAction
  | FormSubmittedAction
  | FormSubmissionCompletedAction
  | SetSearchParamsAction
  | SetPageSizeAction
  | SetBackDataAction
  | setRefreshDashboard
  | setViewSettingDashboard
  | setBaseSettingDashboard;

// アクションクリエーター
export const actionCreators = {
  login: (credential: Credential) =>
  ({
    type: "LOGIN",
    payload: {
      credential,
    }
  } as LoginAction),
  changePassword: (currentPassword: string, newPassword: string) =>
  ({
    type: "CHANGE_PASS",
    payload: {
      currentPassword, newPassword
    }
  } as ChangePassAction),
  init: () =>
  ({
    type: "INIT_APP",
  } as InitAppAction),
  clearSession: () =>
  ({
    type: "CLEAR_SESSION",
  } as ClearSessionAction),
  showMessage: (message: Message, dissmissAfterMs: number = 5000) =>
  ({
    type: "SHOW_MESSAGE",
    payload: {
      message,
      dissmissAfterMs,
    },
  } as ShowMessageAction),
  dismissMessage: (messageId: string) =>
  ({
    type: "DISMISS_MESSAGE",
    payload: {
      messageId,
    },
  } as DismissMessageAction),
  fetch: (
    fetchId: string,
    url: string,
    method: HttpMethod,
    params: object | null,
    multipart: boolean = false,
    json: boolean = false,
  ) =>
  ({
    type: "FETCH",
    payload: {
      fetchId,
      url,
      method,
      params,
      multipart,
      json,
    },
  } as FetchAction),
  initFetch: (fetchId: string) =>
  ({
    type: "INIT_FETCH",
    payload: {
      fetchId,
    },
  } as InitFetchAction),
  discardFetch: (fetchId: string) =>
  ({
    type: "DISCARD_FETCH",
    payload: {
      fetchId,
    },
  } as DiscardFetchAction),
  initForm: (formId: string, initialValue: any) =>
  ({
    type: "INIT_FORM",
    payload: {
      formId,
      initialValue: initialValue,
    },
  } as InitFormAction),
  touchForm: (formId: string, key: string) =>
  ({
    type: "TOUCH_FORM",
    payload: {
      formId,
      key,
    },
  } as TouchFormAction),
  changeForm: (formId: string, name: string, newValue: any) =>
  ({
    type: "CHANGE_FORM",
    payload: {
      formId,
      name,
      newValue,
    },
  } as ChangeFormAction),
  validateForm: (formId: string, errors: FormState["errors"] = {}) =>
  ({
    type: "VALIDATE_FORM",
    payload: {
      formId,
      errors,
    },
  } as ValidateFormAction),
  submitForm: (formId: string) =>
  ({
    type: "SUBMIT_FORM",
    payload: {
      formId,
    },
  } as SubmitFormAction),
  submissionComplete: (formId: string) =>
  ({
    type: "FORM_SUBMISSION_COMPLETED",
    payload: {
      formId,
    },
  } as FormSubmissionCompletedAction),
  resetForm: (formId: string) =>
  ({
    type: "RESET_FORM",
    payload: {
      formId,
    },
  } as ResetFormAction),
  discardForm: (formId: string) =>
  ({
    type: "DISCARD_FORM",
    payload: {
      formId,
    },
  } as DiscardFormAction),
  downloadFile: (
    fetchId: string,
    title: string,
    expectedFilename: string,
    expectedMimeType: string,
    url: string,
    params?: any,
    method?: string,
    searchParams: boolean = false
  ) =>
  ({
    type: "DOWNLOAD_FILE",
    payload: {
      fetchId,
      title,
      expectedFilename,
      expectedMimeType,
      url,
      params,
      method,
      searchParams,
    },
  } as DownloadFileAction),
  setSearchParams: (params: string) =>
  ({
    type: "SET_SEARCH_PARAMS",
    payload: {
      params,
    },
  } as SetSearchParamsAction),
  setPageSize: (pageSize: number) =>
  ({
    type: "SET_PAGE_SIZE",
    payload: {
      pageSize,
    },
  } as SetPageSizeAction),
};

export const selectors = {
  isAuthorize: (state: ApplicationState) => {
    if (state.app.user && state.app.user.cognit_user.challengeName !== 'NEW_PASSWORD_REQUIRED') {
      return true;
    }
    return false;
  },
  isNewPasswordRequired: (state: ApplicationState) =>
    (state.app.user && state.app.user.cognit_user.challengeName === 'NEW_PASSWORD_REQUIRED') ||
    false,
  getFormState: (state: ApplicationState, formId: string) => {
    if (!(state.app.forms && state.app.forms[formId])) {
      return {
        value: undefined,
        valid: false,
        errors: {},
        touched: {},
        error: false,
        submitting: false,
        validating: false,
      };
    }
    const {
      value,
      submitting,
      error,
      pristine,
      errors,
      touched,
      validationNeeded,
    } = state.app.forms[formId];
    let retErrors: { [fieldName: string]: string | undefined } = {};
    if (!pristine) {
      retErrors = errors;
    } else if (errors && touched) {
      for (let prop in touched) {
        retErrors[prop] = errors[prop];
      }
    }
    return {
      value: value,
      valid: !validationNeeded && !error,
      errors: retErrors,
      touched: touched,
      error: error,
      submitting: submitting,
      validating: validationNeeded,
    };
  },
  getFetchState: (state: ApplicationState, fetchId: string): any => {
    if (!(state.app.fetch && state.app.fetch[fetchId])) {
      return {
        loading: false,
      };
    }
    return state.app.fetch[fetchId];
  },
  getContainerState: (state: ApplicationState): any => {
    return state.app.container;
  },
};

export const middleware: Middleware<{}, ApplicationState> = ({
  dispatch,
  getState,
}) => (next) => (incomingAction: Action) => {
  const action = incomingAction as KnownAction;
  next(action);

  if (action.type === "LOGIN") {
    const { credential } = action.payload;

    // サインイン
    Auth.signIn(credential.username, credential.password)
      .then((cognitoUser: any) => {
        const isNewPasswordRequired = cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED';
        if (isNewPasswordRequired) {
          // 強制パスワード変更
          const user = {
            cognito_username: credential.username,
            email: '',
            family_name: '',
            given_name: '',
            c_employee_number: '',
            c_company: 0,
            c_department: 0,
            c_system_manage_flag: 0,
            cognit_user: cognitoUser,
          } as UserDetail;

          dispatch({
            type: "AUTHORIZE",
            payload: { userProfile: user }
          } as AuthorizeAction);
        } else {
          dispatch({
            type: "SESSION_FETCHED",
            payload: { cognitoUser: cognitoUser }
          } as SessionFetchedAction);
        }
      }).catch((err: any) => {
        dispatch(
          actionCreators.showMessage({
            type: "error",
            title: "ログイン",
            body: "ログインに失敗しました。",
          })
        );
      });
  }
  if (action.type === "CHANGE_PASS") {
    const { currentPassword, newPassword } = action.payload;
    const user = getState().app.user;
    // サインイン
    Auth.signIn(user?.cognito_username || '', currentPassword)
      .then(() => {
        // パスワード変更
        Auth.completeNewPassword(user?.cognit_user, newPassword, {})
          .then(() => {
            Auth.currentAuthenticatedUser()
              .then((cognitoUser) => {
                dispatch({
                  type: "SESSION_FETCHED",
                  payload: { cognitoUser: cognitoUser }
                } as SessionFetchedAction);
              }).catch((err: any) => {
                dispatch(
                  actionCreators.showMessage({
                    type: "error",
                    title: "パスワード変更",
                    body: "パスワード変更に失敗しました。",
                  })
                );
              });
          })
          .catch((err: any) => {
            dispatch(
              actionCreators.showMessage({
                type: "error",
                title: "パスワード変更",
                body: "パスワード変更に失敗しました。",
              })
            );
          });
      })
      .catch((err: any) => {
        dispatch(
          actionCreators.showMessage({
            type: "error",
            title: "パスワード変更",
            body: "パスワード変更に失敗しました。",
          })
        );
      })
  }
  if (action.type === "SESSION_FETCHED") {
    const { cognitoUser } = action.payload;
    const tempmap = new Map();
    // ユーザ情報取得
    Auth.userAttributes(cognitoUser)
      .then((userAttr: any) => {
        for (const oneAttr of userAttr) {
          switch (oneAttr.Name) {
            case 'sub': tempmap.set('cognito_username', oneAttr.Value); break;
            case 'email': tempmap.set('email', oneAttr.Value); break;
            case 'family_name': tempmap.set('family_name', oneAttr.Value); break;
            case 'given_name': tempmap.set('given_name', oneAttr.Value); break;
            case 'custom:employee_number': tempmap.set('c_employee_number', oneAttr.Value); break;
            case 'custom:company': tempmap.set('c_company', oneAttr.Value); break;
            case 'custom:department': tempmap.set('c_department', oneAttr.Value); break;
            case 'custom:system_manage_flag': tempmap.set('c_system_manage_flag', oneAttr.Value); break;
          }
        }
        const user = {
          cognito_username: (tempmap.has('cognito_username')) ? tempmap.get('cognito_username') : '',
          email: (tempmap.has('email')) ? tempmap.get('email') : '',
          family_name: (tempmap.has('family_name')) ? tempmap.get('family_name') : '',
          given_name: (tempmap.has('given_name')) ? tempmap.get('given_name') : '',
          c_employee_number: (tempmap.has('c_employee_number')) ? tempmap.get('c_employee_number') : '',
          c_company: (tempmap.has('c_company')) ? tempmap.get('c_company') : 0,
          c_department: (tempmap.has('c_department')) ? tempmap.get('c_department') : 0,
          c_system_manage_flag: (tempmap.has('c_system_manage_flag')) ? tempmap.get('c_system_manage_flag') : 0,
          cognit_user: cognitoUser,
        } as UserDetail;

        dispatch({
          type: "AUTHORIZE",
          payload: { userProfile: user }
        } as AuthorizeAction);

      }).catch((err: any) => {
        dispatch(
          actionCreators.showMessage({
            type: "error",
            title: "認証",
            body: "認証に失敗しました。",
          })
        );
      });
  }
  if (action.type === "INIT_APP") {
    // セッション情報の取得
    Auth.currentAuthenticatedUser()
      .then((cognitoUser) => {
        dispatch({
          type: "SESSION_FETCHED",
          payload: { cognitoUser: cognitoUser }
        } as SessionFetchedAction);
      })
      .catch(() => {
        dispatch({
          type: "CLEAR_SESSION",
        } as ClearSessionAction);
      }).finally(() => {
        dispatch({
          type: "APP_LOADED",
        } as AppLoadedAction);
      });
  }
  if (action.type === "CLEAR_SESSION") {
    Auth.signOut({ global: true }).finally(() => {
      dispatch(push("/"));
    });
  }
  if (action.type === "SHOW_MESSAGE") {
    const appState = getState();
    const message = appState.app.messages.find(
      (m) => m.id === action.payload.message.id
    );
    if (!message) {
      const messageId = action.payload.message.id || generateRandomUUID();
      dispatch({
        type: "MESSAGE_ADDED",
        payload: {
          id: messageId,
          ...action.payload.message,
        },
      } as MessageAddedAction);
      if (action.payload.dissmissAfterMs > 0) {
        setTimeout(() => {
          dispatch({
            type: "DISMISS_MESSAGE",
            payload: {
              messageId: messageId,
            },
          } as DismissMessageAction);
        }, action.payload.dissmissAfterMs);
      }
    }
  }
  if (action.type === "FETCH") {
    Auth.currentSession().then((session) => {
      const { fetchId, url, method, params, multipart, json } = action.payload;
      const fullUrl = process.env.REACT_APP_APIGW_URL + url;
      const appState = getState();
      const dataState = appState.app.fetch[fetchId];
      const token = session.getIdToken().getJwtToken();
      let headers = new Headers();
      headers.append("Authorization", token);

      if (!dataState || dataState.loading) {
        let body: any = undefined;
        if (params) {
          if (json) {
            body = JSON.stringify(params);
          } else {
            if (multipart) {
              body = new FormData();
            } else {
              body = new URLSearchParams();
            }
            if (method === "GET") {
              params.forEach((value: any, key: string) => {
                body.set(key, value);
              });
            }
            else {
              for (let prop in params) {
                if (typeof params[prop] === 'object' && params[prop] !== null) {
                  for (let propChill in params[prop]) {
                    body.set(prop + "." + propChill, params[prop][propChill]);
                  }
                } else {
                  body.set(prop, params[prop]);
                }
              }
            }
          }
        }
        let fetchTask;
        if (method === "GET" || method === "DELETE") {
          let requestUrl;
          if (body) {
            requestUrl =
              fullUrl.indexOf("?") > -1
                ? fullUrl + "&" + body.toString()
                : fullUrl + "?" + body.toString();
          } else {
            requestUrl = fullUrl;
          }
          fetchTask = fetch(requestUrl, { headers: headers, credentials: 'omit', method: method });
        } else {
          if (json) {
            headers.append("Content-Type", "application/json");
            fetchTask = fetch(fullUrl, {
              headers: headers,
              credentials: 'omit',
              method: method,
              body: body,
            });
          } else {
            fetchTask = fetch(fullUrl, {
              headers: headers,
              credentials: 'omit',
              method: method,
              body: body,
            });
          }
        }
        fetchTask
          .then((res) => {
            if (res.status === 401 || res.status === 403) {
              dispatch({
                type: "SESSION_EXPIRED",
              } as SessionExpiredAction);
            }
            const contentType = res.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              res
                .json()
                .then((data) => {
                  dispatch({
                    type: "FETCH_COMPLETED",
                    payload: {
                      fetchId: fetchId,
                      success: res.ok || res.status === 204,
                      data: data,
                    },
                  } as FetchCompletedAction);
                })
                .catch((err) => {
                  dispatch({
                    type: "FETCH_COMPLETED",
                    payload: {
                      fetchId: fetchId,
                      success: false,
                      data: null,
                      error: err,
                    },
                  } as FetchCompletedAction);
                });
            } else {
              res
                .blob()
                .then((data) => {
                  dispatch({
                    type: "FETCH_COMPLETED",
                    payload: {
                      fetchId: fetchId,
                      success: res.ok || res.status === 204,
                      data: data,
                    },
                  } as FetchCompletedAction);
                })
                .catch((err) => {
                  dispatch({
                    type: "FETCH_COMPLETED",
                    payload: {
                      fetchId: fetchId,
                      success: false,
                      data: null,
                      error: err,
                    },
                  } as FetchCompletedAction);
                });
            }
          })
          .catch((err) => {
            dispatch({
              type: "FETCH_COMPLETED",
              payload: {
                fetchId: fetchId,
                success: false,
                data: null,
                error: err,
              },
            } as FetchCompletedAction);
          });
      }
    }).catch((err) => {
      dispatch({
        type: "SESSION_EXPIRED",
      } as SessionExpiredAction);
    });
  }
  if (action.type === "DOWNLOAD_FILE") {
    Auth.currentSession().then((session) => {
      const {
        fetchId,
        title,
        expectedFilename,
        expectedMimeType,
        url,
        params,
        method,
        searchParams,
      } = action.payload;
      const appState = getState();
      const token = session.getIdToken().getJwtToken();
      let headers = new Headers();
      headers.append("Authorization", token);
      let fetchTask;
      let body = undefined;
      if (method === "POST") {
        if (searchParams) {
          body = new URLSearchParams();
          if (params) {
            for (let prop in params) {
              body.set(prop, params[prop]);
            }
          }
          fetchTask = fetch(url, {
            headers: headers,
            credentials: 'omit',
            method: method,
            body: body,
          });
        }
        else {
          if (params) {
            body = JSON.stringify(params);
          }
          headers.append("Content-Type", "application/json");
          fetchTask = fetch(url, {
            headers: headers,
            credentials: 'omit',
            method: method,
            body: body,
          });
        }
      }
      else {
        if (params) {
          body = new URLSearchParams();
          for (let prop in params) {
            body.set(prop, params[prop]);
          }
        }
        let requestUrl;
        if (body) {
          requestUrl =
            url.indexOf("?") > -1
              ? url + "&" + body.toString()
              : url + "?" + body.toString();
        } else {
          requestUrl = url;
        }
        fetchTask = fetch(requestUrl, {
          headers: headers,
          credentials: 'omit',
        });
      }
      let filename: string;
      fetchTask
        .then((res) => {
          if (res.status === 403) {
            dispatch({
              type: "SESSION_EXPIRED",
            } as SessionExpiredAction);
          }
          if (res.ok) {
            filename = expectedFilename;
            return res
              .blob()
              .then((data) => {
                if (data.type !== expectedMimeType) {
                  dispatch(
                    actionCreators.showMessage({
                      type: "error",
                      title: title,
                      body: "ダウンロードに失敗しました。",
                    })
                  );
                  return;
                }
                let link = document.createElement("a");
                link.download = filename;
                link.href = URL.createObjectURL(data);
                link.click();
                URL.revokeObjectURL(link.href);
              })
              .catch((err) => {
                dispatch(
                  actionCreators.showMessage({
                    type: "error",
                    title: title,
                    body: "ダウンロードに失敗しました。",
                    detail: err,
                  })
                );
              });
          } else {
            dispatch(
              actionCreators.showMessage({
                type: "error",
                title: title,
                body: "ダウンロードに失敗しました。",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(
            actionCreators.showMessage({
              type: "error",
              title: title,
              body: "ダウンロードに失敗しました。",
              detail: err,
            })
          );
        })
        .finally(() => {
          dispatch({
            type: "FETCH_COMPLETED",
            payload: {
              fetchId: fetchId,
              success: true,
            },
          } as FetchCompletedAction);
        });
    }).catch((err) => {
      dispatch({
        type: "SESSION_EXPIRED",
      } as SessionExpiredAction);
    });
  }
  if (action.type === "DISMISS_MESSAGE") {
    const appState = getState();
    const message = appState.app.messages.find(
      (m) => m.id === action.payload.messageId
    );
    if (message) {
      dispatch({
        type: "MESSAGE_REMOVED",
        payload: message,
      } as MessageRemovedAction);
    }
  }
  if (action.type === "SESSION_EXPIRED") {
    dispatch(
      actionCreators.showMessage({
        type: "error",
        title: "セッションタイムアウト",
        body: "セッションの継続時間が切れました。再度ログインしてください。",
      })
    );
    dispatch(actionCreators.clearSession());
  }
};

export const reducer: Reducer<AppState> = (state, incomingAction: Action) => {
  const action = incomingAction as KnownAction;
  if (state === undefined) {
    return {
      loading: true,
      messages: [],
      user: null,
      fetch: {},
      forms: {},
      container: {
        producerCode: 0,
        date: "",
      },
      searchParams: "",
      pageSize: 100,
      backData:null,
      isRefresh:false,
      settingView:[],
      settingBase:[]
    };
  }
  switch (action.type) {
    case "INIT_APP":
      return {
        ...state,
        loading: true,
      };
    case "APP_LOADED":
      return {
        ...state,
        loading: false,
      };
    case "CLEAR_SESSION":
      return {
        ...state,
        user: null,
      };
    case "AUTHORIZE":
      return {
        ...state,
        user: action.payload.userProfile,
      };
    case "SESSION_EXPIRED":
      return {
        ...state,
        user: null,
      };
    case "MESSAGE_ADDED":
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case "MESSAGE_REMOVED":
      return {
        ...state,
        messages: state.messages.filter((m) => m !== action.payload),
      };
    case "INIT_FORM": {
      const { formId, initialValue } = action.payload;
      return {
        ...state,
        forms: {
          ...state.forms,
          [formId]: {
            pristine: true,
            initialValue: initialValue,
            value: initialValue,
            validationNeeded: true,
            errors: {},
            touched: {},
            error: false,
            submitting: false,
          },
        },
      };
    }
    case "DISCARD_FORM": {
      const newState = { ...state };
      delete newState.forms[action.payload.formId];
      return newState;
    }
    case "TOUCH_FORM":
      {
        const { formId, key } = action.payload;
        const currentForm = state.forms[formId];
        if (currentForm) {
          const { touched, pristine } = currentForm;
          if (!pristine) {
            return state;
          }
          return {
            ...state,
            forms: {
              ...state.forms,
              [formId]: {
                ...currentForm,
                touched: { ...touched, [key]: true },
              },
            },
          };
        }
      }
      break;
    case "CHANGE_FORM":
      {
        const { formId, name, newValue } = action.payload;
        const currentForm = state.forms[formId];
        if (currentForm) {
          const touched = { ...currentForm.touched };
          currentForm.touched[name] = true;
          return {
            ...state,
            forms: {
              ...state.forms,
              [formId]: {
                ...currentForm,
                value: { ...currentForm.value, [name]: newValue },
                touched: touched,
                validationNeeded: true,
              },
            },
          };
        }
      }
      break;
    case "VALIDATE_FORM":
      {
        const { formId, errors } = action.payload;
        const currentForm = state.forms[formId];
        if (currentForm) {
          let hasError = false;
          for (let prop in errors) {
            if (errors[prop]) {
              hasError = true;
              break;
            }
          }
          return {
            ...state,
            forms: {
              ...state.forms,
              [formId]: {
                ...currentForm,
                errors,
                error: hasError,
                validationNeeded: false,
              },
            },
          };
        }
      }
      break;
    case "SUBMIT_FORM":
      {
        const { formId } = action.payload;
        const currentForm = state.forms[formId];
        if (currentForm) {
          const { pristine, error } = currentForm;
          if (pristine || !error) {
            return {
              ...state,
              forms: {
                ...state.forms,
                [formId]: {
                  ...currentForm,
                  pristine: false,
                  submitting: !error,
                },
              },
            };
          }
        }
      }
      break;
    case "FORM_SUBMITTED":
      {
        const { formId } = action.payload;
        const currentForm = state.forms[formId];
        if (currentForm && !currentForm.error) {
          return {
            ...state,
            forms: {
              ...state.forms,
              [formId]: {
                ...currentForm,
                submitting: true,
              },
            },
          };
        }
      }
      break;
    case "FORM_SUBMISSION_COMPLETED":
      {
        const { formId } = action.payload;
        const currentForm = state.forms[formId];
        if (currentForm) {
          return {
            ...state,
            forms: {
              ...state.forms,
              [formId]: {
                ...currentForm,
                submitting: false,
              },
            },
          };
        }
      }
      break;
    case "RESET_FORM":
      {
        const { formId } = action.payload;
        const currentForm = state.forms[formId];
        if (currentForm) {
          return {
            ...state,
            forms: {
              ...state.forms,
              [formId]: {
                pristine: true,
                initialValue: currentForm.initialValue,
                value: currentForm.initialValue,
                validationNeeded: true,
                errors: {},
                touched: {},
                error: false,
                submitting: false,
              },
            },
          };
        }
      }
      break;
    case "INIT_FETCH":
      {
        const { fetchId } = action.payload;
        const currentData = state.fetch[fetchId];
        if (!currentData || !currentData.loading) {
          return {
            ...state,
            fetch: {
              ...state.fetch,
              [fetchId]: {
                requested: false,
                loading: false,
              },
            },
          };
        }
      }
      break;
    case "DISCARD_FETCH": {
      const { fetchId } = action.payload;
      const newState = { ...state };
      delete newState.fetch[fetchId];
      return newState;
    }
    case "FETCH":
    case "DOWNLOAD_FILE":
      {
        const { fetchId } = action.payload;
        const currentData = state.fetch[fetchId];
        if (currentData) {
          return {
            ...state,
            fetch: {
              ...state.fetch,
              [fetchId]: {
                ...currentData,
                requested: true,
                loading: true,
              },
            },
          };
        }
      }
      break;
    case "FETCH_COMPLETED":
      {
        const { fetchId, success, data, error } = action.payload;
        const currentData = state.fetch[fetchId];
        if (currentData) {
          return {
            ...state,
            fetch: {
              ...state.fetch,
              [fetchId]: {
                ...currentData,
                loading: false,
                success,
                data,
                error,
              },
            },
          };
        }
      }
      break;
    case "SET_SEARCH_PARAMS": {
      const { params } = action.payload;
      return {
        ...state,
        searchParams: params,
      };
    }
    case "SET_PAGE_SIZE": {
      const { pageSize } = action.payload;
      return {
        ...state,
        pageSize: pageSize,
      };
    }
    case "SET_BACK_DATA":{
      const { data } = action.payload
      return {
        ...state,
        backData:data
      };
    }
    case "SET_IS_REFRESH_DASHBOARD":
      const { isRefresh } =  action.payload;
      return {
        ...state,
        isRefresh:isRefresh
      };
    case "SET_VIEW_SETTING_DASHBOARD":
      const {data}  = action.payload;
      let settingViewObject = [...data];
      const clone_view_setting_option:any = [...viewSettingOption];
      for(const item of clone_view_setting_option){
        const show_item = settingViewObject.find((option:any) => option.value == item.value);
        if(show_item){
          item.hiddenFlag = false;
        }else{
          item.hiddenFlag = true;
        }
      }
      settingViewObject = clone_view_setting_option;
      return {
        ...state,
        settingView:settingViewObject
      }
    case "SET_BASE_SETTING_DASHBOARD":
        const base_setting_data = action.payload;
        return {
          ...state,
          settingBase:base_setting_data.data
        }
  }
  return state;
};
